<template>
   <div class="z-[999999999] flex w-full max-w-sm mx-auto mt-4 overflow-hidden rounded-lg shadow-md bg-skin-card">
      <div class="flex items-center justify-center w-12" :class="accentBackground">
         <component :is="icon" class="w-6 h-6 fill-current text-skin-inverted"></component>
      </div>
      <div class="relative flex-1 px-4 py-2 -mx-3">
         <div class="mx-3">
            <span class="font-semibold" :class="accentText">
               <slot name="title" />
            </span>
            <p class="text-sm">
               <slot />
            </p>
         </div>
         <div class="absolute top-0 right-0 z-[999999999] flex px-4 py-3 pointer-events-auto">
            <button @click="$emit('closeNotification')">
               <XIcon class="w-4 h-4 fill-current text-skin-heading"></XIcon>
            </button>
         </div>
      </div>
   </div>
</template>
<script>
/* eslint-disable vue/no-unused-components */
import { CheckIcon, ExclamationCircleIcon, InformationCircleIcon, XIcon } from '@heroicons/vue/solid'

export default {
   name: 'app-notification',
   emits: ['closeNotification'],
   props: {
      notification: {}
   },
   components: {
      CheckIcon,
      InformationCircleIcon,
      ExclamationCircleIcon,
      XIcon
   },
   data() {
      return {
         accentTexts: {
            info: 'text-blue-500',
            success: 'text-green-500',
            error: 'text-red-500'
         },
         accentBackgrounds: {
            info: 'bg-blue-500',
            success: 'bg-green-500',
            error: 'bg-red-500'
         },
         icons: {
            info: 'InformationCircleIcon',
            success: 'CheckIcon',
            error: 'ExclamationCircleIcon'
         }
      }
   },
   computed: {
      type() {
         if (this.notification.type && Object.prototype.hasOwnProperty.call(this.icons, this.notification.type)) {
            return this.notification.type
         }
         return 'info'
      },
      accentBackground() {
         return this.accentBackgrounds[this.type]
      },
      accentText() {
         return this.accentTexts[this.type]
      },
      icon() {
         return this.icons[this.type]
      }
   }
}
</script>
